@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-lato;
  }
}

body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("/public/bg.jpg");
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
  background-repeat: repeat-x;
  background-size: contain;
}

.custom-grid {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
}

/* media query for mobile */
@media (max-width: 768px) {
  body {
    background-repeat: repeat-x;
    background-size: cover;
  }

  .custom-grid {
    display: flex;
    flex-direction: column;
  }
}
